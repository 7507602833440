import './App.css';
import {Component} from "react";

function Companies(props) {
  return <div className={'App-table'}>
    <h2>getCompanies resultat</h2>
    <table>
      <thead>
      <tr>
        <th>slug</th>
        <th>organizationNumber</th>
        <th>testCompany</th>
        <th>hasApiAccess</th>
      </tr>
      </thead>
      <tbody>
      {props.companies.map(c => <>
        <tr>
          <td>{c['slug']}</td>
          <td>{c['organizationNumber']}</td>
          <td>{c['testCompany']+""}</td>
          <td>{c['hasApiAccess']+""}</td>
        </tr>
      </>)}
      </tbody>
    </table>
  </div>
}

class App extends Component {
  state = {}

  componentDidMount() {
    this.fetchUserInfo()
  }

  fetchUserInfo = () => {
    this.isAuthenticated()
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          fetch('/api/fiken/userinfo')
            .then((response) => response.json())
            .then((userInfo) => {
              this.setState({userInfo: userInfo.username});
            })
        }
      })
  }

  getCompanies = () => {
    if (this.state.isAuthenticated) {
      fetch('/api/fiken/companies')
        .then((response) => response.json())
        .then((companies) => this.setState({companies: companies}))
    }
  }

  isAuthenticated = () => {
    return fetch('/api/unauthenticated/isAuthenticated')
      .then((response) => response.text())
      .then((response) => {
        const authenticated = response === 'true';
        this.setState({isAuthenticated: authenticated});
        return authenticated
      });
  }

  login = () => {
    window.location.href = "/oauth2/authorization/fiken"
  }

  logout = () => {
    window.location.href = "/logout"
  }

  render() {
    return <div className={'App'}>
      <header className={'App-header'}>
        <h1 className={'App-title'}>fiken-oauth2-demo</h1>
      </header>

      {!this.state.isAuthenticated &&
        <section className={'App-body'}>
          <div>Du er ikke logget inn.</div>
          <br/>
          <button onClick={this.login} className={'App-button'}>Logg inn / koble til Fiken</button>
          <p>Ved å trykke på knappen over blir du sendt til Fiken CI - hvis du ikke er logget inn der, vil du først måtte logge inn i Fiken.</p>
          <p>Når du er logget inn i Fiken blir du sendt til godkjenningsbildet, her kan du godkjenne om <code>fiken-oauth2-demo</code> skal få tilgang til din konto i Fiken og evt hvilke foretak det gis tilgang til.</p>
          <p>Trykk Godkjenn, og da blir du sendt tilbake hit.</p>
        </section>
      }

      {this.state.isAuthenticated &&
        <section className={'App-body'}>
          <div>Logget inn som {this.state.userInfo}</div>
          <br/>
          <button onClick={this.logout} className={'App-button'}>Logg ut</button>

          <div className={'App-actions'}>
            <button onClick={this.getCompanies} className={'App-button'}>getCompanies()</button>
          </div>

          {this.state.companies && <Companies companies={this.state.companies} />}
        </section>
      }

      <footer className={'App-footer'}>
        {this.state.message}
      </footer>
    </div>

  }

}

export default App;
